.link {
  text-decoration: none;
  color: #005cdc;
  cursor: pointer;
}
.home-button {
  /* color: #2c08ff; */
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
  border-radius: 0px 4px 4px 0px;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
}
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-container1 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-search {
  width: 50%;
  height: 163px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text {
  height: auto;
  align-self: flex-start;
}
.home-text2 {
  font-weight: 900;
}
.home-container2 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.home-textinput {
  width: 100%;
  margin-top: 20px;
  border-radius: 4px 0px 0px 4px;
}
.home-lower {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-results {
  flex: 1;
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
