.result-card-container {
  width: 100%;
  height: var(--dl-size-size-small);
  position: relative;
  max-width: 600px;
  /* box-shadow: 0px 5px 10px 1px #d4d4d4; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.result-card-container1 {
  flex: 0 0 auto;
  width: 200px;
  height: var(--dl-size-size-small);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.result-card-text {
  align-self: flex-start;
}
.result-card-container2 {
  flex: 0 0 auto;
  width: 200px;
  height: var(--dl-size-size-small);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.result-card-container3 {
  padding: 1px;
  flex: 0 0 auto;
  width: 75px;
  height: auto;
  display: flex;
  /* box-shadow: 5px 0px 10px 0px #d4d4d4; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: flex-end;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  /* background-color: #0d643f; */
}
.result-card-text1 {
  color: #ffffff;
  padding: 2px;
  font-size: 13px;
  align-self: center;
}
