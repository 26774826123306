.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.login-container1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-container2 {
  flex: 1;
  width: 50%%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.login-text {
  height: auto;
  align-self: flex-start;
}
.login-form {
  width: auto;
  height: 122px;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
}
.login-input {
  width: 100%;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.login-button {
  text-align: left;
}
